$font-color: #979797;

.full-height {
  height: 100%;
}

.ant-card-head-title {
  color: $font-color;
}

.ant-form-item-label > label {
  color: $font-color;
}

// table scrollbar
.ant-table-body {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(129, 129, 128, 0.85);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background: rgba(0, 4, 40, 0.06);
  }
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 250, 250, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
