@import '~antd/dist/antd.css';
@import './styles/css/global.scss';
@import './styles/css/global.less';

.app {
  width: 100vw;
  height: 100vh;
  color: #e4e4e4;
  background-color: #ececec;
}

.title-bar {
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgb(98, 98, 98);
  background-image: url('assets/img/layout/title-background.png');
  background-size: 100% 100%;
  border: none;
}

.main-content {
  height: 88vh;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.bottom-bar {
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgb(98, 98, 98);
  background-image: url('assets/img/layout/title-background.png');
  background-size: 100% 100%;
  border: none;
}

.logo {
  height: 40px;
  margin-left: 8px;
  margin-right: 12px;
}

.title-logo {
  font-size: 1.4rem;
}

.title-version {
  font-size: 0.8rem;
}

.title-time {
  padding-right: 64px;
  font-size: 1rem;
}

.title-btn {
  margin-right: 24px;
}
